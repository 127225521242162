/* webpackChunkName: "Gateway" */

import { Link, graphql } from "gatsby"
import { anchorClass, containerClass, headlineClass, leftClass, linkClass, rightClass } from "./index.module.scss";

import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import TextRenderer from '../text/renderer'
import arrow from '../../assets/images/arrow-right.svg';
import { pathTo } from '../../routes'

const Gateway = ({ id, headline, teaser, buttonLabel, visual, page }) => {
  if (!headline || !teaser || !buttonLabel || !visual) return null

  return (
    <section id={id}>
      <div className={containerClass}>
        <Link className={anchorClass} to={pathTo(page[0])}>
          <div className={leftClass}>
            <h2 className={headlineClass}>{headline}</h2>
            <div>
              <TextRenderer text={teaser?.teaser}/>
            </div>
            <div className={linkClass}>
              <span>{buttonLabel}</span><img src={arrow} alt=''/>
            </div>
          </div>
          <div className={rightClass}>
            <GatsbyImage
              alt={visual?.description?.description}
              image={visual?.localFile?.childImageSharp?.gatsbyImageData}
            />
          </div>
        </Link>
      </div>
    </section>
  )
}

export default Gateway

export const query  = graphql`
  fragment Gateway on ContentfulGateway {
    __typename
    contentful_id
    headline
    teaser {
      teaser
    }
    buttonLabel
    visual {
      title
      description
      contentful_id
      localFile {
        childImageSharp {
          gatsbyImageData(
            aspectRatio: 1.56
            width: 555
            formats: [AUTO, WEBP, AVIF]
            quality: 75
            transformOptions: {
              cropFocus: CENTER
            }
          )
        }
      }
    }
    page {
      slug
    }
  }
`
